<script setup lang="ts">
const { t } = useI18n({ useScope: 'local' })
const localePath = useLocalePath()
const loading = useState('layout-auth-loading', () => false)
</script>

<template>
  <div id="auth-layout" class="flex min-h-screen">
    <Html>
      <Body class="text-gray-700 font-body bg-gray-50">
        <div class="relative flex-1 px-6 md:px-8">
          <div class="flex justify-between pt-5 mb-16 md:mb-32">
            <NuxtLink :to="localePath('index')">
              <img
                src="/logo/full.svg"
                width="162"
                height="32"
                alt="AppGuide"
                class="w-[120px] h-[24px] md:w-[162px] md:h-[32px]"
              >
            </NuxtLink>

            <UtilsLangSwitcher />
          </div>

          <div class="max-w-md mx-auto">
            <slot />
          </div>

          <div v-if="loading" class="absolute inset-0 z-10 flex items-center justify-center bg-violet-900/50">
            <IconSpinner class="text-white" />
          </div>
        </div>

        <div aria-hidden="true" class="relative items-center justify-center flex-1 hidden overflow-hidden lg:flex bg-violet-700">
          <!-- <p class="text-4xl font-extrabold text-white/20 font-display">
            To Do
          </p> -->
          <div class="auth-layout-bg">
            <div class="auth-layout-bg-img" />
          </div>
        </div>
      </Body>
    </Html>
  </div>
</template>

<style scoped lang="scss">
.auth-layout-bg {
  @apply absolute -inset-4 blur;

  &-img {
    @apply absolute inset-0 bg-cover bg-center;

    background-image: url('/assets/backgrounds/auth-layout-bg.svg');
  }
}
</style>
